import axios from "axios";

const URL = process.env.REACT_APP_ENV === "production" ? "https://api.brydencodes.com/auth/login" : "http://localhost:3001/auth/login";

export function useLogin() {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    }
  }

  async function login(credentials) {
    return axios.post(URL, credentials, config);
  }
  return { login };
}