import React from "react";
import { Dashboard } from "pages";
import { PostForm } from "components";

export function CreatePost(){
  const postFormProps = {
    "title": "Create a New Post",
    "type": "create"
  }
  
  return (
    <Dashboard page="posts">
      <div className="create-post">
        <PostForm {...postFormProps} />
      </div>
    </Dashboard>
  )
}