import React from "react";
import { Dashboard } from "pages";

export function PostsList() {
  return (
    <Dashboard page="posts">
      <div className="list-posts">
        List of all posts
      </div>
    </Dashboard>
  )
}