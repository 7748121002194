import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { Navbar } from "components";
import { useToken } from  "hooks";
import "./Dashboard.scss";

export function Dashboard({ page, children }) {
  const navigate = useNavigate();
  const { getToken } = useToken();

  useEffect(() => {
    if (!getToken()) {
      navigate("/login");
    }
  });

  return (
  <>
    <Navbar />
    <main className={`dashboard main main-${page}`}>
      {children}
    </main>
  </>
  )
}