import React from "react";
import "./Navbar.scss";
import newPostIcon from "images/icons/nav/new-post.png";
import settingsIcon from "images/icons/nav/settings.png";
import darkModeIcon from "images/icons/nav/dark-mode.png";

export function Navbar() {
  return (
    <div className="navbar">
      <div className="nav-links">
        <div className="nav-links-main">
          <a href="/posts/create" className="nav-icon"><img src={newPostIcon} alt="create post" /></a>
        </div>
      <div className="nav-links-settings">
          <div className="toggle-dark-mode nav-icon"><img  src={darkModeIcon} alt="toggle dark mode" /></div>
          <a href="/settings" className="nav-icon"><img src={settingsIcon} alt="settings" /></a>
      </div>
      </div>
    </div>
  )
}