import React from "react";
import { Dashboard } from "pages";

export function EditPost() {
  return (
    <Dashboard page="posts">
      <div className="edit-post">
        Edit post page.
      </div>
    </Dashboard>
  )
}