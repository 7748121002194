import React from "react";
import { Routes, Route } from "react-router-dom";
import { CreatePost, EditPost, Home, Login, PostsList, Settings, ViewPost } from "pages";

export default function Router() {
  return (
    <Routes>
      <Route exact path="login" element={<Login />} />
      <Route path="posts" element={<PostsList />} />
      <Route path="posts/create" element={<CreatePost />} />
      <Route path="posts/edit" element={<EditPost />} />
      <Route path="posts/:postId" element={<ViewPost />} />
      <Route index element={<Home />} />
    </Routes>
  )
}