import { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./Router";
import { AppContext } from "helpers";
import './App.scss';

function App() {
  const [context, setContext] = useState({
    "token": "", 
    "user": {
      "name": "",
      "email": "",
      "username": ""
  }});

  const contextObj = { context, setContext };

  return (
    <BrowserRouter>
      <AppContext.Provider value={contextObj}>
        <Router />
      </AppContext.Provider>
    </BrowserRouter>
  );
}

export default App;
