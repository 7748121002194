function formatCookies() {
  const cookieStr = document.cookie;
  const cookies = cookieStr.split("; ").map((cookie) => {
    const [key, value] = cookie.split("=");
    return {[key]: value};
  });
  return cookies;
}

function deleteToken() {
  document.cookie = "token=; Max-Age=-999999999";
}

function setToken(value, expiration=(60*120)) {
  document.cookie = `token=${value}; max-age=${expiration}`;
  return document.cookie;
}

function getToken() {
  const cookies = formatCookies();
  const found = cookies.filter((cookie) => (cookie["token"]));
  return found[0];
}

export function useToken() {
  return {
    getToken,
    setToken,
    deleteToken
  }
}