import React, { useState } from "react";
import "./TagInput.scss";

export function TagInput({ tags = [], setTags, type = "create" }) {
  const [newTag, setNewTag] = useState("");
  const [invalidWarning, setInvalidWarning] = useState(null);

  const handleAddTag = (e) => {
    e.preventDefault();
    if (tags.includes(newTag)) {
      setInvalidWarning("Tag already exists.");
    } else {
      setTags([...tags, newTag]);
      setNewTag("");
    }
  }

  const handleDeleteTag = (e) => {
    const tag = e.target.dataset.tagName;
    const idx = tags.indexOf(tag);
    const newTags = tags;
    newTags.splice(idx, 1);
    setTags([...newTags]);
  }

  return (
    <form className="tag-input-container" onSubmit={handleAddTag}>
      <div className="tag-input-form">
        <input name="tags" id="tags" value={newTag} onChange={(e) => (setNewTag(e.target.value))} />
      </div>
        <button type="submit" onClick={handleAddTag} className="tag-add-btn">+</button>
      <div className="tag-list">{tags.map((tag) => (<div key={tag} className={`tag-item tag-item-${tag}`} onClick={handleDeleteTag} data-tag-name={tag}>{tag}</div>))}</div>
    </form>
  )
}