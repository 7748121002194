import React, { useEffect, useState } from "react";
import { TagInput } from "components";
import "./PostForm.scss";

export function PostForm({ title, type, inputs }) {
  const INITIAL_VALUES = type === "create" ? {
    "title": "",
    "body": "",
    "tags": [],
  } : inputs;
  const [post, setPost] = useState(INITIAL_VALUES);
  const [tags, setTags] = useState([]);


  useEffect(() => {
    if (type === "create") {
      setPost(INITIAL_VALUES);
    } else {
      setPost(() => ({...inputs}));
    }
  },[]);

  
  const handleChange = (e) => {
    console.log("handling change");
    console.log(e.target.name);
    console.log(e.target.value);
    const { name, value } = e.target;
    setPost((current) => ({
      ...current,
      [name]: value
    }))
  }

  const handleDeletePost = (e) => {
    // Should make a modal appear to ask if you are sure, and then reset all of the values and remove the table from any databases.
  };

  const handleStagePost = (e) => {
    // should save to staged-posts table
  };

  const handleSaveIdea = (e) => {
    // should save to idea posts table
  };

  const handleSaveWip = (e) => {
    // should save to work-in-progress table
  };
  
  const handleSaveDraft = (e) => {
    // should save to drafts table
  };

  // NOTE: Since there is now the idea to make multiple tables for different
  // states of blog posts, there should still be one main master table that
  // has each blog post in it, to have a master id.
  // OR, 
  // can just add a new column to the blogposts table that takes a list of
  // states that the post is in
  // OR
  // can make multiple columns, one for each state, and each column can be a
  // boolean based on if the post is in that stage or not.

  return (
    <div className={`post-form post-form-${type}`}>
      <h1>{title}</h1>
      <form className="post-form-form">
        <div className={`post-form-info-inputs post-form-info-inputs-${type}`}>
          <div className={`post-form-input-group post-form-input-group-${type}`}>
            <label htmlFor="title">Title</label>
            <input required name="title" id="title" value={post.title} onChange={handleChange} />
          </div>
          <div className={`post-form-tags-group post-form-tags-group-${type}`}>
            <label htmlFor="tags">Tags</label>
            <TagInput tags={tags} setTags={setTags} type="create" />
            {/* <input name="tags" id="tags" /> */}
          </div>
        </div>
        <div className={`post-form-body-input-group post-form-body-input-group-${type}`}>
          <textarea required name="body" id="body" value={post.body} onChange={handleChange} />
        </div>
        <div className="post-form-btn-group">
          <button className="post-form-btn-delete" onClick={handleDeletePost}>Delete Post</button>
          <button className="post-form-btn-ideas" onClick={handleSaveIdea}>Save to Ideas</button>
          <button className="post-form-btn-wip" onClick={handleSaveWip}>Save to Work in Progress Posts</button>
          <button className="post-form-btn-drafts" onClick={handleSaveDraft}>Save to Drafts</button>
          <button className="post-form-btn-stage" onClick={handleStagePost}>Stage to Prepared Posts</button>
        </div>
      </form>
    </div>
  )
}