import React from "react";
import { Dashboard } from "pages";

export function ViewPost() {
  return (
    <Dashboard page="posts">
      <div className="view-post">
        View post
      </div>
    </Dashboard>
  )
}