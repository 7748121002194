import React, { useState } from "react";
import { useLogin, useToken } from "hooks";
import "./Login.scss";

export function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useLogin();
  const { setToken } = useToken();

  const handleLogin = async (e) => {
    e.preventDefault();
    const { data } = await login({ username, password });
    if (data.status === "success") {
      const { token, user } = data;
      // CHANGE THIS BEFORE ALL DONE - WILL STAY LOGGED IN FOREVER OTHERWISE
      setToken(token, 9999999999999);
      window.location.href= "/";
    }
  };

  return (
    <main className="main main-login">
      <div className="login-wrapper">
        <div className="login-title">
          <h1>Admin login</h1>
        </div>
        <form onSubmit={handleLogin} className="login-form">
          <div className="login-form-group">
            <label htmlFor="username">Username</label>
            <input type="text" name="username" id="username" value={username} autoComplete="username" onChange={(e) => setUsername(e.target.value)} />
          </div>
          <div className="login-form-group">
            <label htmlFor="password">Password</label>
            <input type="password" name="password" id="password" value={password} autoComplete="current-password" onChange={(e) => setPassword(e.target.value)} />
          </div>
          <div className="login-btn-group">
            <button type="submit">
              Login
            </button>
          </div>
        </form>
      </div>
    </main>
  )
}