import React from "react";
import { Dashboard } from "pages";

export function Home() {


  return (
    <Dashboard page="home">
      <div>Home page</div>
    </Dashboard>
  )
}